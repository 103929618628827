<template>
  <div>
    <div class="row mt-2">
      <div class="col-4">
        <div class="form-group">
          <label class="control-label">First Name</label>
          <input class="form-control" v-model="model.firstName"  type="text"/>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label class="control-label">last Name</label>
          <input class="form-control" v-model="model.lastName"  type="text"/>
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label class="control-label">Username</label>
          <input class="form-control" v-model="model.userName"  type="text"/>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="btn nw-btn-outline-info mr-3" @click="cancel()" v-show="showCancel=='true'">Cancel</button>
      <button class="btn nw-btn-outline-info" @click="search()"><i class="fa fa-search"></i> Search</button>
    </div>
    <table class="table table-hover mt-4">
      <thead><tr><th>First Name</th><th>Last Name</th><th>Username</th></tr></thead>
      <tbody>
        <tr v-for="item in arr" @click="select(item.oid)">
          <td>{{ item.firstName}}</td><td>{{ item.lastName}}</td><td>{{ item.userName }}</td>
        </tr>
      </tbody>

    </table>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Util from '@/util/util.js'

export default {
  props: ['showCancel'],
  data () {
    return {
      model: {
        action: 'search',
        firstName: '',
        lastName: '',
        userName: ''
      },
      arr: []
    }
  },
  methods: {
    search () {
        NetClient.doRequest('rest/user', this.model).then(msg => {
          if(typeof msg.data != 'undefined') this.arr = msg.data
        })
    },
    select (_uid) {
      var user = Util.getObjectByID(this.arr, _uid)
      if(null != user)
      {
        console.log('select' + _uid)
        this.$emit('select',user)
      }
    },
    cancel () {
      this.$emit('cancel')
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
