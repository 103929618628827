<template>
  <div class="container-fluid">
    <div class="card nw-card mt-2">
      <div class="card-header nw-card-header text-left">
        <h4>Admin Console Users</h4>
      </div>
      <div class="card-body text-left">
        <button class="btn nw-btn-outline-info" @click="newUser()"><i class="fa fa-plus"></i> Create New User<br/></button>
        <user-search @select="selectUser"></user-search>
      </div>
    </div>
  </div>
</template>

<script>
import UserSearch from '@/components/UserSearch'

export default {
  components: {UserSearch},
  data () {
    return {
      activePage: 'search'
    }
  },
  methods: {
    selectUser (_user) {
      this.$router.push({ name: 'User', params: { oid: _user.oid}})
    },
    newUser () {
      this.$router.push({ name: 'User', params: { oid: -1}})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
